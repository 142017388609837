import { useNavigate } from 'react-router-dom';
import { getCookie } from '../base/token';
import { useEffect } from 'react';


const Guard = ({ children }) => { 
    const navigate = useNavigate();
    const isAuthenticated = getCookie('token');

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/login');
        }
    }, [isAuthenticated, navigate]);

    if (isAuthenticated) {
        return children;
    }

};

export default Guard;
